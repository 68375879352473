.Sponsors-container {
  height: fit-content;
  width: 100%;
  max-width: 1920px;
  padding: 5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}
.Sponsors-container h3 {
  text-align: center;
  color: #aba2a1;
  font-size: 2rem;
  margin-bottom: 2rem;
  font-family: "switzer";
  font-weight: 700;
}
.logos-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
}
.fill-color {
  width: 280px;
  animation-name: switch-color2;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}
@media (max-width: 400px) {
  .Sponsors-container {
    padding: 5rem 0;
  }
  .Sponsors-container h3 {
    padding: 0rem 1rem;
    font-size: 2rem;
  }
  .fill-color {
    width: 220px;
  }
}

.footer-container {
  width: 100%;
  max-width: 1920px;
  margin: auto;
  height: fit-content;
  background-color: #fff9f3;
}
.footer-container img {
  width: 80px;
}
.footer-container hr {
  width: 1200px;
  height: 1px;
  background-color: rgb(171, 162, 161, 0.5);
  border: none;
  margin: 0 auto;
}
.footer-blocks {
  display: flex;
  margin: auto;
  width: 1200px;
  height: fit-content;
  padding: 6rem 0;
}
.footer-style {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 25%;
  height: 100%;
}
.footer-style h5 {
  margin: 1rem 0 3rem 0;
  font-size: 1rem;
  font-weight: 700;
  color: #231d24;
}
.footer-style .Link-style,
.footer-style a {
  margin-bottom: 1rem;
  color: #231d24;
  text-decoration: none;
  width: fit-content;
}
.footer-style .Link-style:hover,
.footer-style a:hover {
  text-decoration: underline;
}
.footer-style p {
  font-size: 0.9rem;
  color: #aba2a1;
  margin: 0;
}
.footer-copy {
  color: #aba2a1;
  display: flex;
  margin: auto;
  width: 1200px;
}
.footer-copy p {
  box-sizing: border-box;
  margin: 3rem 0;
}
@media (max-width: 1400px) {
  .footer-blocks {
    flex-direction: column;
    padding: 5rem 0;
    width: 85%;
  }
  .footer-container hr {
    width: 90%;
  }
  .footer-container img {
    margin-bottom: 3rem;
  }
  .footer-style {
    width: 100%;
  }
  .footer-style h5 {
    margin-bottom: 2rem;
  }
  .footer-copy {
    width: 90%;
  }
}

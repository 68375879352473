.page-head-container {
  width: 100%;
  max-width: 1920px;
  height: 50vh;
  min-height: 600px;
  max-height: 700px;
  margin: auto;
  background-color: #fff9f3;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  margin-bottom: 6rem;
}
.page-head-container h1 {
  z-index: 11;
  font-weight: 600;
  font-size: 4rem;
  width: 60%;
  text-align: center;
}
.page-head-container h6 {
  z-index: 11;
  cursor: not-allowed;
  user-select: none;
  font-size: 1.2rem;
  margin: 0;
}
.page-head-background {
  position: absolute;
  z-index: 10;
  opacity: 0.8;
  width: 100%;
  height: 100%;
  background-image: url(../imgs/dots.svg);
  background-size: 1050px;
}
@media (max-width: 500px) {
  .page-head-container h1 {
    font-size: 3rem;
    width: 90%;
    text-align: center;
  }
}

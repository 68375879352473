.home-container {
  margin: auto;
  width: 100%;
  max-width: 1920px;
  height: 100vh;
  min-height: 800px;
  max-height: 1200px;
  position: relative;
  overflow: hidden;
  background-color: #fff9f3;
  display: flex;
}
.home-background {
  position: absolute;
  user-select: none;
  z-index: 0;
  height: 100%;
  width: 100%;
}
.div-background-web {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 10%;
  bottom: 0px;
  overflow: auto;
}
.home-background .background-web {
  z-index: 0;
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 450px;
  bottom: 0;
  top: auto;
}
.home-left {
  position: relative;
  top: 180px;
  color: #231d24;
  margin-left: 10%;
  z-index: 100;
  display: flex;
  flex-direction: column;
  width: fit-content;
  width: 500px;
  height: fit-content;
}
.home-title {
  z-index: 10;
}
.home-title h3 {
  padding: 0;
  font-size: 1.5rem;
  margin-bottom: -2.5rem;
  font-weight: 600;
}
.home-title h1 {
  font-weight: 600;
  font-size: 6rem;
  line-height: 6rem;
  margin-bottom: 1.5rem;
}
.home-right {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  top: 160px;
  margin-right: 10%;
  right: 0;
  left: auto;
  z-index: 10;
  user-select: none;
  width: 100%;
}
.home-image {
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  object-fit: fill;
  width: 100%;
  max-width: 1000px;
  z-index: 10;
}
.dots-background {
  z-index: 10;
  opacity: 0.8;
  width: 100%;
  height: 85%;
  background-image: url(../imgs/dots.svg);
  background-size: 1050px;
}
.home-title-btn {
  display: flex;
}
.home-title-btn a {
  cursor: pointer;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "switzer";
  padding: 1rem 1.5rem;
  margin: 0;
  border-radius: 0.8rem;
  background-color: #fb8500;
  transition: 0.3s;
  font-size: 1.2rem;
  color: #ffffff;
  text-decoration: none;
}
.home-title-btn a:hover {
  background-color: #f89018;
}
.home-title-btn button {
  color: #aba2a1;
  margin-left: 1rem;
  font-size: 1.2rem;
  font-family: "switzer";
  text-decoration: underline;
  cursor: pointer;
  position: relative;
  border-style: none;
  background-color: transparent;
}
.home-title-btn button img {
  position: absolute;
  bottom: -2rem;
  right: 1.55rem;
  width: 150px;
}
@media (max-width: 1500px) {
  .home-container {
    flex-direction: column;
    height: fit-content;
    padding-bottom: 10rem;
    padding-top: 15rem;
  }
  .home-background .div-background-web {
    height: 15%;
    position: absolute;
    bottom: 140px;
    background-color: #fff9f3;
  }
  .home-right {
    position: static;
  }
  .home-left {
    position: static;
    align-items: center;
    margin: auto;
    margin-bottom: 5rem;
    width: 800px;
  }
  .home-title {
    text-align: center;
  }
  .home-title h1 {
    margin-top: 1rem;
    justify-content: center;
  }
  .home-title h3 {
    margin: 0;
    font-size: 2rem;
  }
  .home-image {
    min-width: 900px;
  }
}
@media (max-width: 1000px) {
  .home-left {
    width: fit-content;
  }
}
@media (max-width: 766px) {
  .home-title-btn {
    flex-direction: column;
    align-items: center;
  }
  .home-title-btn a {
    margin-bottom: 1rem;
  }
  .home-title-btn button img {
    top: 40px;
  }
  .home-image {
    margin-top: 1rem;
    min-width: 120vw;
  }
}
@media (max-width: 470px) {
  .home-container {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .home-title h1 {
    font-size: 4rem;
    line-height: 4rem;
  }
  .home-title h3 {
    margin-top: 5rem;
    font-size: 1.8rem;
  }
  .home-background .div-background-web {
    bottom: 20px;
  }
}
@media (max-width: 300px) {
  .home-title h1 {
    font-size: 15vw;
    line-height: 3rem;
  }
  .home-title h3 {
    font-size: 8vw;
    line-height: 3rem;
  }
}
@media (max-height: 860px) and (min-width: 1680px) {
  .home-container {
    min-height: 900px;
  }
}

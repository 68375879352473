.event-post-container {
  width: 550px;
  height: fit-content;
  padding: 2.5rem 0rem;
  border-radius: 2.5rem;
  background: #231d24;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 10;
  overflow: hidden;
}
.event-background {
  position: absolute;
  z-index: 9;
  opacity: 1;
  width: 100%;
  height: 100%;
  background-image: url(../imgs/dots-light.svg);
  background-size: 650px;
}
.event-head {
  z-index: 10;
  display: flex;
  width: 100%;
  height: 120px;
  position: relative;
}
.event-head-details {
  z-index: 10;
  display: flex;
  flex-direction: column;
  width: 45%;
  height: 100%;
  padding-left: 1rem;
  margin-left: 2rem;
  overflow-y: scroll;
  color: #fff9f3;
  direction: rtl;
}
.event-head-details::-webkit-scrollbar {
  top: 10rem;
  background: #fff9f3;
  width: 5px;
  border-radius: 2rem;
}
.event-head-details::-webkit-scrollbar-thumb {
  background: #fb8500;
  border-radius: 2rem;
  width: 5px;
}
.event-detail {
  margin-bottom: 0.2rem;
  direction: ltr;
  display: flex;
  width: 100%;
}
.event-detail p {
  z-index: 10;
  margin: 0;
  font-size: 1.1rem;
  font-weight: 600;
}
.event-icons {
  font-size: 1.1rem;
  z-index: 10;
  margin-right: 0.5rem;
  margin-top: 0.2rem;
}
.event-head-date {
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-left: 3rem;
  box-sizing: border-box;
}
.event-head-date p {
  z-index: 10;
  color: #fff9f3;
  margin: 0;
  font-size: 4rem;
  margin: -1rem;
  user-select: none;
}
.event-head-date .event-month {
  z-index: 10;
  font-size: 1.2rem;
  font-weight: 500;
  margin: 0;
}
.event-title {
  z-index: 10;
  height: fit-content;
  max-height: 90px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 90%;
  display: flex;
  box-sizing: border-box;
  padding-left: 3rem;
  overflow-y: auto;
}
.event-title h4 {
  height: fit-content;
  padding-right: 0.5rem;
  display: flex;
  z-index: 10;
  margin: 0;
  font-weight: 600;
  color: #fff9f3;

  font-size: 2rem;
}
.event-title::-webkit-scrollbar {
  position: absolute;
  background: #fff9f3;
  width: 7px;
  border-radius: 2.5rem;
}
.event-title::-webkit-scrollbar-thumb {
  border-radius: 2.5rem;
  background: #fb8500;
  width: 7px;
}
.event-about {
  z-index: 10;
  height: fit-content;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.event-about-btn {
  z-index: 10;
  height: 50px;
  border-radius: 1rem;
  width: 85%;
  background-color: #fff9f3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0rem 1rem;
  cursor: pointer;
  transition: 0.4s;
}
.event-about-btn span {
  color: #231d24;
  font-weight: 700;
  font-size: 1rem;
  user-select: none;
}
.event-about-btn .about-icon {
  color: #231d24;
  font-size: 1.5rem;
}
.event-about-btn:hover {
  background-color: #efe7df;
}
.read-p {
  z-index: 10;
  display: flex;
  margin-left: 3rem;
  width: 85%;
}
.read-p-none {
  display: none;
}
.read-p p {
  color: #efe7df;
  font-size: 1.1rem;
}
@media (max-width: 600px) {
  .event-post-container {
    width: 450px;
    padding-top: 3rem;
    padding-bottom: 3rem;
    box-sizing: border-box;
  }
  .event-head-details {
    flex-direction: column;
    justify-content: normal;
    width: 30%;
  }
  .event-head-icon {
    top: -1rem;
  }
  .event-head-icon img {
    width: 60px;
  }
}
@media (max-width: 480px) {
  .event-post-container {
    width: 90%;
    margin: auto;
    padding: 2rem 0;
  }
  .event-head {
    flex-direction: column;
    height: fit-content;
  }
  .event-head-details {
    z-index: 10;
    display: flex;
    flex-direction: column;
    width: fit-content;
    height: 100px;
    max-width: 75%;
    margin-top: 7rem;
    padding-left: 1rem;
    margin-right: 3rem;
    overflow-y: scroll;
    color: #fff9f3;
    direction: rtl;
  }
  .event-head-date {
    position: absolute;
    height: fit-content;
    margin: 0;
    top: 0%;
    bottom: auto;
    left: 10%;
  }
  .event-head-icon {
    top: 2.1%;
    right: 10%;
  }
  .event-head-icon img {
    width: 70px;
  }
  .event-detail p {
    font-size: 1rem;
  }
  .event-head-date p {
    font-size: 4rem;
    margin: -1rem;
  }
  .event-head-date .event-month {
    font-size: 1.2rem;
  }
  .event-title {
    padding-left: 2rem;
  }
  .event-title h4 {
    font-size: 1.5rem;
  }
  .read-p {
    width: 80%;
    margin: auto;
  }
}

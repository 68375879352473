.about-chapter-container {
  height: 300px;
  background-color: #aba2a1;
  width: 100%;
  max-width: 1200px;
  border-radius: 3rem;
  margin: 6rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.about-chapter-block {
  width: fit-content;
  height: 40%;
  border-radius: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.about-chapter-block h3 {
  z-index: 11;
  font-family: "switzer";
  font-weight: 700;
  font-size: 2.2rem;
  margin: 0 0 1rem 0;
  color: #fff9f3;
}
.about-chapter-block p {
  font-size: 1rem;
  z-index: 11;
  width: 700px;
  color: #fff9f3;
  margin: 0 0 1rem 0;
}
.about-link-btn {
  z-index: 11;
  text-decoration: none;
  cursor: pointer;
  border-style: none;
  background-color: transparent;
  color: #fff9f3;
}
.about-link-btn:hover {
  text-decoration: underline;
}
.about-icon-btn {
  margin-right: 0.3rem;
  font-size: 1rem;
}
.about-link-btn span {
  font-size: 1.1rem;
  font-family: "switzer";
  font-weight: 600;
}
.about-dots-background {
  position: absolute;
  z-index: 10;
  opacity: 0.8;
  width: 100%;
  height: 140%;
  background-image: url(../imgs/dots.svg);
  background-size: 650px;
  background-repeat: repeat;
  top: -100px;
  left: -30px;
  bottom: auto;
}
@media (max-width: 1300px) {
  .about-chapter-container {
    box-sizing: border-box;
    padding: 3rem 3rem;
    width: 90%;
    border-radius: 2.5rem;
    height: fit-content;
    margin: 5rem auto;
  }
  .about-chapter-block {
    width: fit-content;
  }
  .about-chapter-block p {
    width: fit-content;
  }
  .about-link-btn span {
    font-size: 1rem;
  }
}

.header-container {
  display: flex;
  justify-content: center;
  z-index: 100;
  width: 100%;
  position: fixed;
  z-index: 200;
}
.header-div {
  z-index: 200;
  margin-top: 2rem;
  height: 90px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.header-no-scroll {
  animation-name: headerAnimation2;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
}
.header-scroll {
  animation-name: headerAnimation;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
}
nav {
  z-index: 200;
  width: 80%;
  max-width: 1530px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav-container div {
  display: inline-block;
  margin-left: 50px;
}
.nav-container .home-margin {
  margin-left: 0px;
}
.link-style {
  font-size: 1.2rem;
  font-family: "switzer";
  font-weight: 500;
  color: #231d24;
  text-decoration: none;
  transition: 0.3s;
  z-index: 200;
}
.link-style:hover {
  color: #fb8500;
}
.active {
  text-decoration: underline;
}
.logo-style {
  z-index: 100;
  width: 230px;
  z-index: 200;
}
.short-logo {
  display: none;
  width: 80px;
}
.btn-display {
  display: none;
  cursor: pointer;
  border-style: none;
  background-color: transparent;
}
.discord-btn {
  text-decoration: none;
  cursor: pointer;
  border-style: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "switzer";
  font-size: 1rem;
  color: #ffffff;
  padding: 0.8rem;
  border-radius: 0.8rem;
  background-color: #fb8500;
  transition: 0.3s;
}
.discord-btn:hover {
  background-color: #f89018;
}
.discord-icon {
  margin-right: 0.5rem;
  font-size: 1.4rem;
}
.icon-style {
  z-index: 10;
  font-size: 2.2rem;
  margin: 0;
  padding: 0;
}
.menu-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
@keyframes headerAnimation {
  0% {
    margin-top: 2rem;
  }
  100% {
    margin-top: 0;
    background-color: white;
    box-shadow: rgb(171, 162, 161, 0.3) 0px 0px 20px;
  }
}
@keyframes headerAnimation2 {
  0% {
    margin-top: 0;
    background-color: white;
    box-shadow: rgb(171, 162, 161, 0.3) 0px 0px 20px;
  }
  100% {
    margin-top: 2rem;
    background-color: transparent;
  }
}

@media (max-width: 1300px) {
  .header-container {
    width: 100%;
    position: fixed;
  }
  .header-div {
    width: 100%;
    height: 75px;
  }

  .icon-style {
    font-size: 1.7rem;
  }
  .nav-container {
    z-index: 9;
    display: none;
    flex-direction: column;
    position: fixed;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: auto;
    background-color: #ffffff;
    transition: 2s;
  }
  .open {
    display: flex;
  }
  .nav-container div {
    margin: 0 0 40px 0;
  }
  .link-style {
    font-size: 1.5rem;
  }
  .btn-display {
    color: #231d24;
    display: flex;
  }
  .discord-btn {
    right: 0;
    padding: 0.7rem 0.6rem;
    border-radius: 0.6rem;
    font-size: 0.7rem;
    margin-right: 0.5rem;
  }
  .discord-icon {
    font-size: 0.8rem;
  }
}
@media (max-width: 600px) {
  .short-logo {
    display: flex;
  }
  .logo-style {
    display: none;
  }
}
@media (max-height: 500px) {
  .nav-container {
    flex-direction: row;
  }
  .nav-container div {
    margin: 0 20px 0 0;
  }
}

.events-content {
  margin: 0 auto 6rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.event-view {
  display: none;
}
.none-style {
  color: #aba2a1;
  padding: 8rem 15rem;
  border-radius: 2rem;
  border: #aba2a1 dotted 1px;
}
.events-buttons {
  width: 380px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  border-radius: 1rem;
  margin-bottom: 3rem;
}
.events-buttons button {
  color: #aba2a1;
  font-size: 1rem;
  cursor: pointer;
  border-style: none;
  border-radius: 1rem;
  background-color: #ffffff;
  transition: 0.3s;
  width: 180px;
}
#events-active {
  color: white;
  padding: 1rem;
  border-radius: 1rem;
  background-color: #f89018;
  transition: 0.3s;
}
#events-inactive:hover {
  padding: 1rem;
  border-radius: 1rem;
  background-color: #fff9f3;
}
@media (max-width: 600px) {
  .none-style {
    padding: 6rem 30%;
  }
}
@media (max-width: 400px) {
  .events-buttons {
    flex-direction: column;
    width: 100%;
    height: 100px;
  }
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: auto;
  touch-action: manipulation;
  background-color: #ffffff;
  font-family: "switzer";
  font-weight: 500;
}
html,
* {
  font-family: "switzer";
  font-weight: 500;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
::selection {
  background-color: #f8e5d6;
}
@font-face {
  font-family: "switzer";
  font-weight: 500;
  src: url(../../public/Switzer-Medium.otf);
}
@font-face {
  font-family: "switzer";
  font-weight: 600;
  src: url(../../public/Switzer-Semibold.otf);
}
@font-face {
  font-family: "switzer";
  font-weight: 700;
  src: url(../../public/Switzer-Bold.otf);
}

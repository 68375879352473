.teams-container {
  width: 1200px;
  height: fit-content;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 6rem;
  justify-content: space-between;
}
@media (max-width: 1280px) {
  .teams-container {
    width: 100%;
    flex-direction: column;
    justify-content: baseline;
    margin: auto;
    margin-bottom: 3rem;
    align-items: center;
  }
}

.aboutacm-conatiner {
  display: flex;
  width: 100%;
  max-width: 1200px;
  height: 500px;
  margin: auto;
}
.left-block {
  width: 50%;
}
.left-block img {
  min-width: 400px;
  width: 100%;
}
.right-block {
  width: 50%;
  height: 100%;
}
.right-block h2 {
  font-family: "switzer";
  font-weight: 700;
  color: #231d24;
  font-size: 2.2rem;
  line-height: 2.5rem;
  margin: 3rem 0rem 2rem 4rem;
}
.right-block .p1 {
  font-family: "switzer";
  font-weight: 500;
  font-size: 1rem;
  margin: 0rem 0rem 1rem 4rem;
}
.right-block .p2 {
  font-family: "switzer";
  font-weight: 500;
  font-size: 1rem;
  margin: 0rem 0rem 1rem 4rem;
}
.right-block a {
  cursor: pointer;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "switzer";
  font-weight: 600;
  border-radius: 0.8rem;
  color: #aba2a1;
  text-decoration: none;
  margin: 0rem 0rem 0rem 4rem;
}
.right-block ins {
  font-family: "switzer";
  font-weight: 600;
  font-size: 0.9rem;
}
@media (max-width: 1300px) {
  .aboutacm-conatiner {
    height: fit-content;
    align-items: center;
    flex-direction: column;
  }
  .left-block {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .left-block img {
    overflow: hidden;
    width: 70%;
    min-width: 300px;
  }
  .right-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 0;
    padding: 0;
    width: 70%;
  }
  .right-block h2 {
    text-align: center;
    line-height: 3rem;
    margin-left: 0;
    margin-right: 0;
  }
  .right-block .p1,
  .right-block .p2 {
    text-align: center;
    font-size: 1rem;
    margin-left: 0;
    margin-right: 0;
  }
  .right-block a {
    margin: 0;
  }
}
@media (max-width: 300px) {
  .left-block img {
    overflow: hidden;
    width: 70%;
    min-width: 80%;
  }
}
@media (min-width: 700px) and (max-width: 1300px) {
  .right-block .p1,
  .right-block .p2 {
    font-size: 1.5rem;
  }
}

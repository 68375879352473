.acm-team-container {
  width: 385px;
  height: fit-content;
  padding-bottom: 2.5rem;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #cadced;
}
.team-icon {
  margin-top: 2.5rem;
  margin-bottom: 2rem;
  height: 60px;
}
.team-img {
  width: 80%;
  height: 270px;
  margin-bottom: 0.5rem;
}
.team-text {
  color: #231d24;
  font-family: "switzer";
  font-weight: 500;
  font-size: 1rem;
  width: 80%;
  margin-bottom: 1rem;
}
.acm-team-btn-container {
  width: 80%;
}
.acm-team-btn {
  left: 0;
  cursor: pointer;
  width: fit-content;
  display: flex;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  font-family: "switzer";
  font-weight: 500;
  font-size: 1rem;
  padding: 0.5rem 1.2rem;
  border-radius: 0.5rem;
  background-color: #226dc6;
  font-size: 1rem;
  color: #ffffff;
  transition: 0.3s;
}
#purple {
  background-color: #e4dcef;
}
#purple-btn {
  background-color: #7433d1;
}
#green {
  background-color: #ebf2e1;
}
#green-btn {
  background-color: #82bc13;
}
.acm-team-btn:hover {
  background-color: #297bde;
}
#purple-btn:hover {
  background-color: #833ae8;
}
#green-btn:hover {
  background-color: #8ecd18;
}
@media (max-width: 1280px) {
  .team-icon {
    height: 90px;
  }
  .team-img {
    height: 50vw;
  }
  .acm-team-container {
    margin: auto;
    margin-bottom: 3rem;
    width: 80%;
  }
}
@media (max-width: 1100px) {
  .team-icon {
    height: 90px;
  }
  .acm-team-container {
    margin-bottom: 3rem;
    width: 80%;
  }
}
@media (max-width: 500px) {
  .team-icon {
    height: 70px;
  }
  .acm-team-container {
    margin-bottom: 3rem;
    width: 90%;
  }
  .team-text {
    font-size: 1rem;
  }
  .team-img {
    height: 55vw;
  }
}
@media (max-width: 400px) {
  .team-icon {
    height: 16vw;
  }
  .acm-team-container {
    margin-bottom: 2rem;
    width: 90%;
  }
}

.officer-container {
  width: 250px;
  display: flex;
  padding: 2rem;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #aba2a1;
}
.officer-container p {
  overflow: hidden;
  text-overflow: ellipsis;
  width: fit-content;
  max-width: 250px;
  text-wrap: nowrap;
  margin-bottom: 0.3rem;
}
.office-div img {
  width: 100%;
}
.style-hide {
  display: none;
}
.office-div {
  background-color: bisque;
  overflow: hidden;
  height: 120px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  margin-bottom: 0.5rem;
}
.officer-icon {
  color: #aba2a1;
  font-size: 2.5rem;
  margin: 0;
  padding: 0;
}
